import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-rc.0_react-dom@19.0.0-rc-01172397-20240716_react@19.0.0-rc-01172397-20240716__rea_mbyvsc3bfdkjqldixat2ldmkf4/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-rc.0_react-dom@19.0.0-rc-01172397-20240716_react@19.0.0-rc-01172397-20240716__rea_mbyvsc3bfdkjqldixat2ldmkf4/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-rc.0_react-dom@19.0.0-rc-01172397-20240716_react@19.0.0-rc-01172397-20240716__rea_mbyvsc3bfdkjqldixat2ldmkf4/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-rc.0_react-dom@19.0.0-rc-01172397-20240716_react@19.0.0-rc-01172397-20240716__rea_mbyvsc3bfdkjqldixat2ldmkf4/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-rc.0_react-dom@19.0.0-rc-01172397-20240716_react@19.0.0-rc-01172397-20240716__rea_mbyvsc3bfdkjqldixat2ldmkf4/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-rc.0_react-dom@19.0.0-rc-01172397-20240716_react@19.0.0-rc-01172397-20240716__rea_mbyvsc3bfdkjqldixat2ldmkf4/node_modules/next/dist/client/components/render-from-template-context.js");
